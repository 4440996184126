<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
<PageGenerate :callbackSuccess="callbackSuccess" />
</v-container>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/classroom'
  import model from '@/models/items/student'
  //EOC
 import PageGenerate from "@/components/Moderator/Express/Count/PageGenerate";
  import { mapState } from 'vuex'
  export default {
    components:{
      PageGenerate,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'parent',
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name.classrooms"),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.parent && this.parent.name) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent),
        exact:true,
      })
      //
    this.breadcrumbs.push({
      text:this.$t("view.PageBackendClassroomExpress.manage_pass_title"),
      to: { name: "PageModeratorClassroomStudentExpressPassManage",params:{parentId:this.$route.params.parentId,parent:this.parent} },
      exact: true,
    });
    //EOC
      //
      this.breadcrumbs.push({
        text:this.$t("view.PageBackendClassroomExpress.generate_pass_title"),
        to:{name:'PageModeratorClassroomExpressGenerate',params:{parentId:this.$route.params.parentId,parent:this.parent}},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        this.$router.push({name:'PageModeratorClassroomStudentExpressPassManage',params:{parentId:this.$route.params.parentId,parent:this.parent}})
      },
    }
  }
</script>