<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>{{$t("view.PageBackendClassroomExpress.generate_pass_title")}}</v-card-title>
        <v-card-text>
          <template>
            <FormCount :value="form['count']" @input="form['count'] = $event" />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> {{$t("action.cancel")}} </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            {{$t("action.confirm")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormCount from "./FormCount.vue";
export default {
  components: {
    FormCount,
  },
  computed: mapState({
    //
  }),
  props: ["callbackSuccess"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {
      count: 1,
    },
  }),
  created() {
    //BOC:[url]
    this.api.url = `${this.$api.servers.moderator}/v1/classroom/${this.$route.params.parentId}/express/generate`;
    //EOC
    //BOC:[api]
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
     this.callbackSuccess(resp)
    };
    //EOC
  },
  mounted() {},
  methods: {
   
    back() {
      this.$router.go(-1);
    },
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.generatePass();
      }
       else {
          this.api.callbackError('Please check the form.')
        }
    },
    generatePass() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
  },
};
</script>