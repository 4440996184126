<template>
  <div>
    <v-text-field  :rules="[() => !!inputVal || $t('rules.count')]" min="0" v-model="inputVal" type="number" :label="$t('model.prop.count')"></v-text-field>
  </div>
</template>

<script>
export default {
  components: {
    //
  },
 computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  props: [
    "value"
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>